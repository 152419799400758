import { User, V2 } from 'shared';
import { UxType } from 'store/UI';
import { userHasRole } from 'store/User/selectors';
import { store } from 'store/store';
import { isVendorUser } from 'store/v2/User/utils';

import {
  ADMIN_PATH,
  APP_ROOT_PATH,
  HOME_PATH,
  PROPERTIES_LISTINGS_PATH,
  PROPERTIES_PAYOUTS_PATH,
  ROOT_PATH,
  VENDOR_TASKS_PATH
} from './paths';
import { UserRole } from './types';

export function validUserForRole({
  user,
  userV2,
  role,
  uxType
}: {
  user: User;
  userV2: V2.User;
  role: UserRole;
  uxType?: UxType;
}): boolean {
  const isProspect = !!user.office_leads.length;
  const isTenantAdmin = !!user.user_types.map(ut => ut.name).includes('tenant_admin');
  const isTenant = isTenantAdmin || !!user.user_types.map(ut => ut.name).includes('tenant_employee');
  const isTenantUx = uxType === 'tenant';

  if (role === 'tenant' && isTenantUx) {
    return isTenant;
  } else if (role === 'tenantAdmin' && isTenantUx) {
    return isTenantAdmin;
  } else if (role === 'user') {
    return !!user.id;
  } else if (role === 'employee') {
    return !!user.customer;
  } else if (role === 'superAdmin') {
    return !!user.is_admin;
  } else if (role === 'vendorAdmin') {
    return !!user.user_types.map(ut => ut.name).includes('vendor_admin');
  } else if (role === 'vendor') {
    return isVendorUser(userV2);
  } else if (role === 'hasWorkspaceRoles') {
    return !!user.licensor_roles.length || !!user.workspace_roles.length;
  } else if (role === 'hasCustomerAdminPlans') {
    return !!user.customer_admin_plans;
  } else if (role === 'hasCustomerAdminPlansOrIsProspect') {
    return !!user.customer_admin_plans || isProspect;
  } else if (role === 'isProspect') {
    return !!user.office_leads.length;
  } else {
    return false;
  }
}

export function defaultLoginRedirectPath(user: User) {
  if (userHasRole(user, 'admin')) {
    return ADMIN_PATH;
  } else return defaultListingPath(user);
}

export function defaultListingPath(user: User) {
  if (userHasRole(user, 'listing_admin')) {
    return PROPERTIES_LISTINGS_PATH;
  } else if (userHasRole(user, 'payout_admin') || userHasRole(user, 'payout_viewer')) {
    return PROPERTIES_PAYOUTS_PATH;
  } else if (user.user_types.some(ut => ['vendor_admin', 'vendor_employee'].includes(ut.name))) {
    return VENDOR_TASKS_PATH;
  } else if (userHasRole(user, 'customer')) {
    return APP_ROOT_PATH;
  } else {
    return HOME_PATH;
  }
}

export function homePath() {
  const isLoggedIn = !!store.getState().User.user;

  return isLoggedIn ? HOME_PATH : ROOT_PATH;
}
